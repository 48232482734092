import { css, keyframes } from "emotion";

const fadeInZoom = keyframes`
    from {opacity: 0; transform: scale(0);}
    to {opacity: 1; transform: scale(1);}
`;

const swipeIn = keyframes`
    from {opacity: 0; transform: translateX(-100%);}
    to {opacity: 1; transform: translateX(0);}
`;

export const experiences = isMobile => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: auto;
  ${isMobile
    ? `
    padding: 30px 20px;
  `
    : `
    padding: 40px;
  `}
  box-sizing: border-box;
  background-image: linear-gradient(#e8daff0a, #6034a31a);
  min-height: fit-content;
`;

export const experiencesBody = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const companyBody = isMobile => css`
  display: flex;
  max-width: 1100px;
  width: 100%;
  ${isMobile
    ? `
    flex-direction: column;
  `
    : `
    margin-top: 45px;
  `}
`;

export const companyLogo = (isMobile, companyName) => css`
  display: flex;
  align-items: baseline;
  justify-content: center;
  ${isMobile
    ? `
    ${
      companyName === "justdial"
        ? `
    min-width: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
    > img {
      width: 70px;
    }
    `
        : `
    min-width: 100px;
    margin-bottom: 20px;
    > img {
      width: 90px;
    }
    `
    }
  `
    : `
  min-width: 150px;
  > img {
    width: 120px;
  }
  `}
  &.hidden img {
    opacity: 0;
    transform: scale(0);
  }
  &.animate-in-element img {
    opacity: 1;
    animation: ${fadeInZoom} 0.5s;
  }
`;

export const companyDetails = (isMobile, companyName) => css`
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  ${isMobile
    ? `
  ${
    companyName === "lyearn"
      ? `border-left: 5px solid #1086ca;`
      : `border-left: 5px solid #1172bf;`
  }
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 5px;
  
  `
    : `
  ${
    companyName === "lyearn"
      ? `border-left: 10px solid #1086ca;`
      : `border-left: 10px solid #1172bf;`
  }
  padding-left: 30px;
  padding-bottom: 30px;
  margin-left: 30px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 10px;
  
  `}
  overflow: hidden;
  div.hidden {
    opacity: 0;
    transform: translateX(-100%);
  }
  div.animate-in-element {
    opacity: 1;
    animation: ${swipeIn} 0.5s;
  }
`;

export const companyName = (isMobile, companyName) => css`
  font-weight: 600;
  ${companyName === "lyearn" ? `color: #1086ca;` : `color: #1172bf;`}
  text-transform: uppercase;
  ${isMobile
    ? `
  font-size: 20px;
  padding-bottom: 10px;
  
  `
    : `
  font-size: 30px;
  padding-bottom: 15px;
  
  `}
`;

export const appointedAs = isMobile => css`
  color: #585858;
  ${isMobile
    ? `
  font-size: 16px;
  padding-bottom: 6px;
  `
    : `
  font-size: 20px;
  padding-bottom: 10px;
  `}
`;

export const currentRole = isMobile => css`
  color: #585858;
  ${isMobile
    ? `
  font-size: 16px;
  padding-bottom: 6px;
  `
    : `
  font-size: 20px;
  padding-bottom: 10px;
  `}
`;

export const link = css`
  text-decoration: none;
  color: inherit;
`;

export const projectName = (isMobile, companyName) => css`
  ${companyName === "lyearn" ? `color: #1086ca;` : `color: #1172bf;`}
  ${isMobile
    ? `
  font-size: 14px;
  padding-bottom: 6px;
  `
    : `
  font-size: 18px;
  padding-bottom: 10px;
  `}
`;

export const projectDescription = isMobile => css`
  margin-bottom: 20px;
  > div {
    color: #717171;
  }
  ${isMobile
    ? `
    > div {
      font-size: 14px;
      line-height: 20px;
    }
  `
    : `
    > div {
      line-height: 25px;
    }
  `}
`;

export const myRole = isMobile => css`
  > div {
    color: #717171;
  }
  ${isMobile
    ? `
    > div {
      font-size: 14px;
      line-height: 20px;
    }
  `
    : `
    > div {
      line-height: 25px;
    }
  `}
`;

export const appointmentDetails = isMobile => css`
  color: #7c7c7c;
  ${isMobile
    ? `
  font-size: 14px;
  padding-bottom: 6px;
  `
    : `
  font-size: 18px;
  padding-bottom: 10px;
  `}
`;

export const myRoleHeader = isMobile => css`
  ${isMobile
    ? `
  padding-bottom: 6px;
  font-size: 16px !important;
  
  `
    : `
  padding-bottom: 10px;
  font-size: 22px !important;
  
  `}
  color: #878787;
`;
