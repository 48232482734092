import React from "react";
import * as s from "./style";

class Menu extends React.Component {
  render() {
    const { isMobile, showMobileMenu, menuItemClick } = this.props;
    return (
      <div className={s.menuContainer(isMobile, showMobileMenu)}>
        <div
          className={s.menuItem(isMobile, showMobileMenu)}
          onClick={() => menuItemClick("ABOUT")}
        >
          About Me
        </div>
        <div
          className={s.menuItem(isMobile, showMobileMenu)}
          onClick={() => menuItemClick("SKILLS")}
        >
          Skills
        </div>
        <div
          className={s.menuItem(isMobile, showMobileMenu)}
          onClick={() => menuItemClick("EXPERIENCES")}
        >
          Experiences
        </div>
        <div
          className={s.menuItem(isMobile, showMobileMenu)}
          onClick={() => menuItemClick("SERVICES")}
        >
          Services
        </div>
        <div
          className={s.menuItem(isMobile, showMobileMenu)}
          onClick={() => menuItemClick("CONTACT")}
        >
          Contact
        </div>
      </div>
    );
  }
}

export default Menu;
