import React from "react";
import { observer } from "mobx-react";
import Header from "../Header";
import Model from "../../model";
import Body from "../Body";
import StickyHeader from "../StickyHeader";
import Menu from "../../components/Menu";

@observer
class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.model = new Model(this);
    this.model.actions.bindScroll();
    this.model.actions.bindResize();
  }
  componentDidMount() {
    this.model.actions.handleAnimations();
  }
  render() {
    const { store, actions } = this.model;
    const { isMobile, showMobileMenu } = store;
    const { menuItemClick } = actions;
    return (
      <div>
        <Header model={this.model} />
        <StickyHeader model={this.model} />
        {isMobile ? (
          <Menu
            isMobile={isMobile}
            showMobileMenu={showMobileMenu}
            menuItemClick={menuItemClick}
          />
        ) : null}
        <Body model={this.model} />
      </div>
    );
  }
}

export default Portfolio;
