import React from "react";
import Particles from "../../components/Particles";
import * as s from "./style";
import { observer } from "mobx-react";
import HeaderContainer from "../../components/HeaderContainer";

@observer
class Header extends React.Component {
  render() {
    const { model } = this.props;
    const { actions, store } = model;
    const { isMobile, showMobileMenu } = store;
    const { onViewMoreClick, toggleMobileMenu, menuItemClick } = actions;
    return (
      <div className={s.header()}>
        {/* <Particles color="linear-gradient(141deg, #4B41E9 0%, #512198 51%, #521E92 75%)"/> */}
        <Particles
          id="main-header"
          color="linear-gradient(155deg,#333150 10%,#6435ab 50%,#230e3c 90%)"
        />
        <HeaderContainer
          isMobile={isMobile}
          showMobileMenu={showMobileMenu}
          toggleMobileMenu={toggleMobileMenu}
          menuItemClick={menuItemClick}
        />
        <h1 className={s.aboutMe(isMobile)}>
          <div className="hidden">
            Hello, I am <span className={s.myName()}>Tapas Kumar</span>.
          </div>
          <div className="hidden">I am a Full Stack Web Developer.</div>
          <button
            className={`${s.viewMore(isMobile)} hidden`}
            onClick={onViewMoreClick}
          >
            View More
          </button>
        </h1>
      </div>
    );
  }
}

export default Header;
