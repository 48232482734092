import { css, keyframes } from "emotion";

const fadeIn = keyframes`
    from {opacity: 0; stroke-dasharray: 0, 900;}
    to {opacity: 1;}
`;
const fadeInImg = keyframes`
    from {opacity: 0; transform: translate(-50%, -50%) rotate(-180deg);}
    to {opacity: 1; transform: translate(-50%, -50%) rotate(0deg);}
`;

export const progressRingCircle = () => css`
  transition: 0.5s all;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const progressCircleContainer = isMobile => css`
  position: relative;
  ${isMobile
    ? `
    width: 80px;
    height: 80px;
  `
    : `
    width: 120px;
    height: 120px;`}
  &.hidden circle {
    stroke-dasharray: 0, 900 !important;
    opacity: 0;
  }
  &.animate-in-element circle {
    animation: ${fadeIn} 2s;
  }
  &.hidden img {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(-180deg);
  }
  &.animate-in-element img {
    animation: ${fadeInImg} 2s;
  }
`;

export const image = isMobile => css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${isMobile
    ? `
    max-width: 38px;
  `
    : `
    max-width: 60px;
  `}
`;
