import github from "../images/github.png";
import bitbucket from "../images/bitbucket.jpeg";
import rabbitvcs from "../images/rabbitvcs.png";

const getVCS = () => [
  {
    text: "Github",
    imageSrc: github,
    imageAlt: "Github",
    percent: 100,
    color: "#161515"
  },
  {
    text: "BitBucket",
    imageSrc: bitbucket,
    imageAlt: "BitBucket",
    percent: 100,
    color: "#2784fd"
  },
  {
    text: "Rabbit VCS",
    imageSrc: rabbitvcs,
    imageAlt: "Rabbit VCS",
    percent: 100,
    color: "#d8b686"
  }
];

export default getVCS;
