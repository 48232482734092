import React from "react";
import * as s from "./style";
import Menu from "../Menu";

class HeaderContainer extends React.Component {
  getBurgerIcon = () => {
    const { showMobileMenu, toggleMobileMenu } = this.props;
    return (
      <div className={s.burgerIconContyainer()} onClick={toggleMobileMenu}>
        <div className={s.hamburger()}>
          <div className={s.hamburgerBox(showMobileMenu)}>
            <div className={s.hamburgerInner(showMobileMenu)} />
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { isMobile, showMobileMenu, menuItemClick } = this.props;
    const logo = "</>";
    return (
      <div className={s.headerContainer(isMobile)}>
        <div className={s.logoContainer()}>
          <div className={s.logo(isMobile)}>{logo}</div>
          <div className={s.logoText(isMobile)}>tapaskumar</div>
        </div>
        {isMobile ? (
          this.getBurgerIcon()
        ) : (
          <Menu
            isMobile={isMobile}
            showMobileMenu={showMobileMenu}
            menuItemClick={menuItemClick}
          />
        )}
      </div>
    );
  }
}

export default HeaderContainer;
