import { css, keyframes } from "emotion";

const fadeZoom = keyframes`
    from {opacity: 0; transform: scale(0.7);}
    to {opacity: 1; transform: scale(1);}
`;

const slideInView = keyframes`
    from {opacity: 0; transform: translateX(-100%);}
    to {opacity: 1; transform: translateX(0);}
`;

export const skillsBody = isMobile => css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: auto;
  ${isMobile
    ? `
    padding: 30px 20px;
  `
    : `
    padding: 40px;
  `}
  box-sizing: border-box;
  background-image: linear-gradient(#e8daff0a, #6034a31a);
  min-height: fit-content;
`;

export const skillsView = isMobile => css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  ${isMobile
    ? `
    justify-content: space-around;
  `
    : `
    padding-top: 30px;
    justify-content: center;
  `}
`;

export const progressContainer = isMobile => css`
  ${isMobile ? `padding: 10px;` : `padding: 15px 40px;`}
`;

export const skillName = (isMobile, color) => css`
  color: ${color};
  ${isMobile
    ? `
    font-size: 14px;
    margin-top: 6px;
  `
    : `
    font-size: 18px;
    margin-top: 10px;
  `}
`;

export const skillRating = (isMobile, color) => css`
  color: ${color};
  ${isMobile
    ? `
    font-size: 12px;
    margin-top: 2px;`
    : `
    font-size: 14px;
    margin-top: 4px;
  `}
`;

export const vcsView = isMobile => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${isMobile
    ? `
    justify-content: space-around;
  `
    : `
    justify-content: center;
  `}
`;

export const subHeader = isMobile => css`
  ${isMobile
    ? `
    font-size: 16px;
    margin: 20px 0 6px;
  `
    : `
    font-size: 20px;
    margin: 30px 0 10px;
  `}
  text-transform: uppercase;
  color: #8a8a8a;
  &.hidden {
    opacity: 0;
    transform: scale(0.7);
  }
  &.animate-in-element {
    opacity: 1;
    animation: ${fadeZoom} 1s;
  }
`;

export const vcsList = isMobile => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const vc = isMobile => css`
  ${isMobile ? `padding: 10px;` : `padding: 15px 40px;`}
`;

export const vcsName = (isMobile, color) => css`
  color: ${color};
  ${isMobile
    ? `
  font-size: 14px;
  margin-top: 6px;
`
    : `
  font-size: 18px;
  margin-top: 10px;
`}
`;

export const extraSkills = isMobile => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #6d6d6d;
  > div {
    ${isMobile
      ? `
      margin-top: 15px;
    `
      : `
      margin-top: 10px;
    `}
  }
  ${isMobile
    ? `
      margin-top: 10px;
      font-size: 14px;
      text-align: end;
  `
    : `
      font-size: 18px;
      margin-top: 15px;
  `}
  &.hidden {
    opacity: 0;
    transform: translateX(-100%);
  }
  &.animate-in-element {
    opacity: 1;
    animation: ${slideInView} 1s;
  }
`;
