import React from "react";
import * as s from "./style";

class Header extends React.Component {
  render() {
    const { text, isMobile } = this.props;
    return <div className={`${s.header(isMobile)} hidden`}>{text}</div>;
  }
}

export default Header;
