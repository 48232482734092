import React from "react";
import * as s from "./style";

class ProgressCircle extends React.Component {
  render() {
    const { color, percent, imageSrc, imageAlt, isMobile } = this.props;
    const r = isMobile ? 34 : 52;
    var circumference = r * 2 * Math.PI;
    const offset = circumference - (percent / 100) * circumference;
    const width = isMobile ? 80 : 120;
    const height = isMobile ? 80 : 120;
    const strokeWidth = isMobile ? 3 : 4;
    const cx = isMobile ? 40 : 60;
    const cy = isMobile ? 40 : 60;
    return (
      <div className={`${s.progressCircleContainer(isMobile)} hidden`}>
        <svg className="progress-ring" width={width} height={height}>
          <circle
            className={s.progressRingCircle(isMobile)}
            stroke={color}
            strokeWidth={strokeWidth}
            fill="transparent"
            r={r}
            cx={cx}
            cy={cy}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={`${offset}`}
          />
        </svg>
        {imageSrc ? (
          <img src={imageSrc} alt={imageAlt} className={s.image(isMobile)} />
        ) : null}
      </div>
    );
  }
}

export default ProgressCircle;
