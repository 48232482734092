import { action } from "mobx";

class Actions {
  constructor(store) {
    this.store = store;
  }

  @action
  onViewMoreClick = () => {
    // document.getElementById('main-body').scrollIntoView({ behavior: 'smooth' });
    const mainBody = document.getElementById("main-body");
    this.scrollToView(mainBody);
  };

  @action
  bindScroll = () => {
    document.addEventListener("scroll", this.handleScroll);
  };

  @action
  bindResize = () => {
    window.addEventListener("resize", this.handleResize);
  };

  @action
  handleResize = () => {
    const windowWidth = window.outerWidth;
    if (windowWidth <= 768) {
      this.store.isMobile = true;
    } else {
      this.store.isMobile = false;
    }
  };

  @action
  handleScroll = e => {
    const windowHeight = this.store.windowHeight;
    const scrollY = window.scrollY;
    if (scrollY >= windowHeight - this.store.threshold) {
      this.store.showStickyHeader = true;
    } else {
      this.store.showStickyHeader = false;
    }
  };

  @action
  scrollToView = domElement => {
    const elementTop = domElement.offsetTop;
    window.scrollTo({
      top: elementTop - this.store.threshold,
      behavior: "smooth"
    });
  };

  @action
  toggleBodyScroll = scroll => {
    if (scroll) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  @action
  toggleMobileMenu = () => {
    this.store.showMobileMenu = !this.store.showMobileMenu;
    this.toggleBodyScroll(!this.store.showMobileMenu);
  };

  @action
  menuItemClick = menu => {
    const domElement = document.getElementById(menu);
    this.store.showMobileMenu = false;
    this.toggleBodyScroll(true);
    domElement && this.scrollToView(domElement);
  };

  @action
  handleAnimations = () => {
    const windowHeight = this.store.windowHeight;
    const animateHTML = function() {
      let elems;
      function init() {
        elems = document.querySelectorAll(".hidden");
        addEventHandlers();
        checkPosition();
      }
      function addEventHandlers() {
        window.addEventListener("scroll", checkPosition);
        window.addEventListener("resize", init);
      }
      function checkPosition() {
        for (let i = 0; i < elems.length; i++) {
          let positionFromTop = elems[i].getBoundingClientRect().top;
          if (positionFromTop - windowHeight <= 0) {
            elems[i].className = elems[i].className.replace(
              "hidden",
              "animate-in-element"
            );
          }
        }
      }
      return {
        init: init
      };
    };
    animateHTML().init();
  };
}

export default Actions;
