import React from "react";
import * as s from "./style";
import Header from "../../components/Header";
import getSkillsList from "../../helper/getSkillsList";
import ProgressCircle from "../../components/ProgressCircle";
import getVCS from "../../helper/getVCS";

class Skills extends React.Component {
  getSkillsView = () => {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    const skills = getSkillsList();
    return (
      <div className={s.skillsView(isMobile)}>
        {skills.map((skill) => {
          return (
            <div className={s.progressContainer(isMobile)} key={skill.text}>
              <ProgressCircle {...skill} isMobile={isMobile} />
              <div className={s.skillName(isMobile, skill.color)}>
                {skill.text}
              </div>
              <div className={s.skillRating(isMobile, skill.color)}>
                {skill.percent / 10}/10
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  getVersionControlSystem = () => {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    const vcs = getVCS();
    return (
      <div className={s.vcsView(isMobile)}>
        <div className={`${s.subHeader(isMobile)} hidden`}>
          Version Control Systems
        </div>
        <div className={s.vcsList(isMobile)}>
          {vcs.map((vc) => {
            return (
              <div className={s.vc(isMobile)} key={vc.text}>
                <ProgressCircle {...vc} isMobile={isMobile} />
                <div className={s.vcsName(isMobile, vc.color)}>{vc.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  extraSkills = () => {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={`${s.extraSkills(isMobile)} hidden`}>
        <div>
          - Have worked with Popular Libraries such as Material UI, StripeJS,
          Lodash, Tailwind.
        </div>
        <div>
          - Apart from above technologies, I also have worked with Java, C, C++,
          VB.NET, PHP, ASP.NET, Typescript.
        </div>
        <div>
          - I also have working experince in Google Firebase, and Video Chat
          Apis provided by Agora, Twilio, Jitsi.
        </div>
        <div>
          - Mostly used Architecture to build Projects MVVM. Also worked with
          MVC Architecture.
        </div>
      </div>
    );
  };
  render() {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={s.skillsBody(isMobile)} id="SKILLS">
        <Header text="Skills" isMobile={isMobile} />
        {this.getSkillsView()}
        {this.getVersionControlSystem()}
        {this.extraSkills()}
      </div>
    );
  }
}

export default Skills;
