import { css, keyframes } from "emotion";

const appear = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export const menuContainer = (isMobile, showMobileMenu) => css`
  display: flex;
  ${isMobile
    ? `
        position: fixed;
        flex-direction: column;
        top: 75px;
        width: 100vw;
        left: 0px;
        background: rgba(255, 255, 255, 0.9);
        z-index: 3;
        ${
          showMobileMenu
            ? `
            padding: 30px 0;
            height: calc(100vh - 75px);
            transition: 0.3s all ease-out;
        `
            : `
            height: 0px;
            transition: 0.3s all ease-in;
        `
        }
    `
    : `flex-wrap: wrap;`}
`;

export const menuItem = (isMobile, showMobileMenu) => css`
  cursor: pointer;
  text-transform: uppercase;
  ${isMobile
    ? `
        padding: 25px;
        animation-name: ${appear};
        animation-duration: 1s;
        font-size: 22px;
        font-weight: 600;
        color: #230e3c;
        ${
          showMobileMenu
            ? `
          display: block;
        `
            : `
          display: none;
        `
        }
    `
    : `
        color: white;
        font-size: 20px;
        padding: 0 20px;
        :last-child {
            padding-right: 0
        }
    `}
`;
