const getContactHelpLinks = () => [
  {
    text: "Open Source Development",
    subject: "Open Source Development - TapasKumar.in",
    body: "I am looking help in a Open Source Development."
  },
  {
    text: "Web Technology Seminar",
    subject: "Web Technology Seminar - TapasKumar.in",
    body: "I am looking for Web Technology Seminar."
  },
  {
    text: "Pre Placement Talk",
    subject: "Pre Placement Talk - TapasKumar.in",
    body: "I am looking for Pre Placement Talk in my Institution."
  },
  {
    text: "Develop Portfolio",
    subject: "Develop Portfolio - TapasKumar.in",
    body: "I am looking to Develop my own Portfolio."
  },
  {
    text: "I Need Help",
    subject: "I Need Help - TapasKumar.in",
    body: "I am looking help."
  },
  {
    text: "Others",
    subject: "Others - TapasKumar.in",
    body: ""
  }
];

export default getContactHelpLinks;
