import React from "react";
import AboutMe from "../AboutMe";
import Skills from "../Skills";
import * as s from "./style";
import Experiences from "../Experiences";
import Services from "../Services";
import ContactMe from "../ContactMe";
import Footer from "../Footer";

class Body extends React.Component {
  render() {
    const { model } = this.props;
    return (
      <div className={s.bodyRoot()} id="main-body">
        <AboutMe model={model} />
        <Skills model={model} />
        <Experiences model={model} />
        <Services model={model} />
        <ContactMe model={model} />
        <Footer model={model} />
      </div>
    );
  }
}

export default Body;
