import { css, keyframes } from "emotion";

const moveInLeft = keyframes`
    from {opacity: 0; transform: translateX(-100%);}
    to {opacity: 1; transform: translateX(0);}
`;

const moveInRight = keyframes`
    from {opacity: 0; transform: translateX(100%);}
    to {opacity: 1; transform: translateX(0);}
`;

export const aboutMe = (isMobile) => css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    155deg,
    #cccccc 25%,
    #ffffff 75%,
    #220d3c 100%
  );
  height: auto;
  ${isMobile
    ? `
    padding: 30px 20px;
  `
    : `
    padding: 40px;
  `}
  padding-bottom: 0;
  box-sizing: border-box;
  min-height: fit-content;
  /* background-image: linear-gradient(#13547a, #80d0c7); */
`;

export const aboutMeBody = (isMobile) => css`
  display: flex;
  width: 100%;
  max-width: 1024px;
  align-items: center;
  color: #4e4e4e;
  ${isMobile
    ? `
    flex-direction: column;
  `
    : ``}
`;

export const myDetails = (isMobile) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${isMobile
    ? `
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  `
    : `
    width: 70%;
    font-size: 20px;
    line-height: 30px;
  `}
`;

export const myDetailsBody = (isMobile) => css`
  ${isMobile
    ? `
    text-align: center;
  `
    : `
    text-align: right;
  `}
  > div {
    margin-bottom: 20px;
  }
  transition: 0.5s ease-in;
  &.hidden {
    opacity: 0;
  }
  &.animate-in-element {
    opacity: 1;
    animation: ${moveInLeft} 0.5s;
  }
`;

export const myPic = () => css`
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in;
  &.hidden {
    opacity: 0;
  }
  &.animate-in-element {
    opacity: 1;
    animation: ${moveInRight} 0.5s;
  }
`;

export const myImage = () => css`
  width: 280px;
  bottom: -4px;
  position: relative;
`;
