import { css } from "emotion";

export const stickyHeader = (isMobile, showStickyHeader) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s;
  z-index: 3;
  ${isMobile
    ? `
      height: 75px;
      ${
        showStickyHeader
          ? "transform: translate3D(0px, 0px, 0px);"
          : "transform: translate3D(0px, -75px, 0px);"
      }
  `
    : `
      height: 100px;
      ${
        showStickyHeader
          ? "transform: translate3D(0px, 0px, 0px);"
          : "transform: translate3D(0px, -100px, 0px);"
      }
  `}
`;
