import React from "react";
import * as s from "./style";
import lyearn from "../../images/lyearn.png";
import justdial from "../../images/justdial.png";
import Header from "../../components/Header";

class Experiences extends React.Component {
  render() {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={s.experiences(isMobile)} id="EXPERIENCES">
        <Header text="Experiences" isMobile={isMobile} />
        <div className={s.experiencesBody(isMobile)}>
          <div className={s.companyBody(isMobile)}>
            <div className={`${s.companyLogo(isMobile)} hidden`}>
              <img src={lyearn} alt="Lyearn" />
            </div>
            <div className={s.companyDetails(isMobile, "lyearn")}>
              <div className={`${s.companyName(isMobile, "lyearn")} hidden`}>
                Lyearn Pvt. Ltd.
              </div>
              <div className={`${s.appointedAs(isMobile)} hidden`}>
                Appointed as: SOFTWARE DEVELOPER
              </div>
              <div className={`${s.currentRole(isMobile)} hidden`}>
                Current Role: FULL STACK DEVELOPER
              </div>
              <div className={`${s.projectName(isMobile, "lyearn")} hidden`}>
                <a
                  href="https://www.lyearn.com"
                  className={s.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Lyearn (www.lyearn.com)
                </a>
              </div>
              <div className={`${s.projectDescription(isMobile)} hidden`}>
                <div>
                  - Lyearn is a learning website designed to deliver learning to
                  multiple users of a organisation at same time.
                </div>
                <div>
                  - Technology that enables better teaching and learning and can
                  scale to as many people as you need it to.
                </div>
                <div>
                  - Lyearn empowers organisations to deliver a deeply engaging
                  experience to their learners where they are, anytime,
                  anywhere.
                </div>
                <div>
                  - It works online as well as offline and works on all devices.
                </div>
              </div>
              <div className={`${s.myRole(isMobile)} hidden`}>
                <div className={s.myRoleHeader(isMobile)}>My Role</div>
                <div>
                  - Development of High Performance web pages using technologies
                  such as React JS, Mobx, Emotion Js, GraphQl.
                </div>
                <div>
                  - Prime focus on writing a neat and clean code followed by
                  MVVM architecture.
                </div>
                <div>
                  - Developing Responsive Websites that look great on any device
                  or platform.
                </div>
                <div>
                  - Developing Challenging WebPages and ready to learn on every
                  step.
                </div>
                <div>- Writing Modular Codes and Bug Fixing</div>
              </div>
            </div>
          </div>
          <div className={s.companyBody(isMobile)}>
            <div className={`${s.companyLogo(isMobile, "justdial")} hidden`}>
              <img src={justdial} alt="Lyearn" />
            </div>
            <div className={s.companyDetails(isMobile, "justdial")}>
              <div className={`${s.companyName(isMobile, "justdial")} hidden`}>
                Justdial Pvt. Ltd.
              </div>
              <div className={`${s.appointedAs(isMobile)} hidden`}>
                Appointed as: SOFTWARE ENGINEER
              </div>
              <div className={`${s.currentRole(isMobile)} hidden`}>
                Role: FULL STACK DEVELOPER
              </div>
              <div className={`${s.appointmentDetails(isMobile)} hidden`}>
                Appointed as Intern from Jan 2017 –July 2017.
              </div>
              <div className={`${s.appointmentDetails(isMobile)} hidden`}>
                Appointed as Permanent Employee from July 2017
              </div>
              <div className={`${s.projectName(isMobile, "justdial")} hidden`}>
                <a
                  href="https://www.jdomni.com"
                  className={s.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  JD Omni (www.jdomni.com)
                </a>
              </div>
              <div className={`${s.projectName(isMobile)} hidden`}>
                <a
                  href="https://www.yourownwebsite.justdial.com"
                  className={s.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Justdial Your Own Website (www.yourownwebsite.justdial.com)
                </a>
              </div>
              <div className={`${s.projectDescription(isMobile)} hidden`}>
                <div>
                  - JDOmni is a recent launched website for small business which
                  claims to be a plug and play to manage inventory and billing.
                  It basically gives an e-commerce website to users where they
                  can manage their products and sales.
                </div>
                <div>
                  - It works to its best on every device and platform whether
                  its IPad, Mac, IOS Mobile Devices, Android Devices, Windows
                  PC.
                </div>
                <div>
                  - Its highly customisable and very easy to use. You can
                  develop your website within minutes.
                </div>
              </div>
              <div className={`${s.myRole(isMobile)} hidden`}>
                <div className={s.myRoleHeader(isMobile)}>My Role</div>
                <div>
                  - Design of Dynamic UI using technologies like HTML5, CSS3,
                  JavaScript, Angular JS, JQuery, Bootstrap, ReactJS, Redux,
                  NodeJS.
                </div>
                <div>- Mostly followed MVC architecture.</div>
                <div>- Bug Fixing, writing optimized code.</div>
                <div>
                  - Performing migration of webpages that were designed using
                  old technologies to newer technologies such as ReactJS.
                </div>
                <div>
                  - Writing Optimized Common Codes and Logics that can be used
                  in multiple ways to provide dynamic results.
                </div>
                <div>
                  - Writing Codes that work well in Android, Desktop as well as
                  IOS devices.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Experiences;
