import { css, keyframes } from "emotion";

const fadeIn = keyframes`
    from {opacity: 0; transform: scale(.7,.7)}
    to {opacity: 1;}
`;

export const header = () => css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  .hidden {
    opacity: 0;
  }
  .animate-in-element {
    animation: ${fadeIn} 1.4s;
  }
`;

export const aboutMe = (isMobile) => css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  font-weight: normal;
  ${isMobile
    ? `
        font-size: 24px;
        padding: 0 20px;
        line-height: 40px;
    `
    : `
        font-size: 40px;
        line-height: 50px;
        padding: 0 40px;
    `}
`;

export const myName = () => css`
  background: -webkit-linear-gradient(#ccc, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const viewMore = (isMobile) => css`
  display: flex;
  border: 2px solid;
  padding: 0 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  background: transparent;
  border-color: white;
  color: white;
  display: flex;
  align-items: center;
  font-family: Montserrat, sans-serif;
  :focus {
    outline: none;
  }
  ${isMobile
    ? `
        font-size: 18px;
        height: 44px;
    `
    : `
        font-size: 20px;
        height: 54px;
    `}
`;
