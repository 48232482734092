import React from "react";
import { observer } from "mobx-react";
import HeaderContainer from "../../components/HeaderContainer";
import Particles from "../../components/Particles";
import * as s from "./style";

@observer
class StickyHeader extends React.Component {
  render() {
    const { model } = this.props;
    const { store, actions } = model;
    const { toggleMobileMenu, menuItemClick } = actions;
    const { showMobileMenu, showStickyHeader, isMobile } = store;
    return (
      <div className={s.stickyHeader(isMobile, showStickyHeader)}>
        <Particles
          id="sticky-header"
          color="linear-gradient(155deg,#333150 10%,#6435ab 50%,#230e3c 90%)"
        />
        <HeaderContainer
          isMobile={isMobile}
          showMobileMenu={showMobileMenu}
          toggleMobileMenu={toggleMobileMenu}
          menuItemClick={menuItemClick}
        />
      </div>
    );
  }
}

export default StickyHeader;
