import React from "react";
import Header from "../../components/Header";
import * as s from "./style";
import getContactHelpLinks from "../../helper/getContactHelpLinks";

class ContactMe extends React.Component {
  getContactMeBody = () => {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    const contactHelpLinks = getContactHelpLinks();
    return (
      <div className={s.helpLinkbody(isMobile)}>
        {contactHelpLinks.map(helpLink => (
          <a
            href={`mailto:tapaskumar05@gmail.com?subject=${helpLink.subject}&body=${helpLink.body}`}
            target="_top"
            className={s.mailToLink}
            key={helpLink.text}
          >
            <div className={`${s.helpLinkTab(isMobile)} hidden`}>
              {helpLink.text}
            </div>
          </a>
        ))}
      </div>
    );
  };
  render() {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={s.contactMe(isMobile)} id="CONTACT">
        <Header text="Contact" isMobile={isMobile} />
        <div className={s.contactMeBody(isMobile)}>
          {this.getContactMeBody()}
        </div>
      </div>
    );
  }
}

export default ContactMe;
