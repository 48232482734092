import { css, keyframes } from "emotion";

const fadeIn = keyframes`
    from {opacity: 0; transform: scale(0.5);}
    to {opacity: 1; transform: scale(1);}
`;

export const services = isMobile => css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    166deg,
    #dcdcdc 10%,
    #ffffff 80%,
    #5b1ca9 100%
  );
  height: auto;
  ${isMobile
    ? `
    padding: 30px 20px;
  `
    : `
    padding: 40px;
  `}
  box-sizing: border-box;
  min-height: fit-content;
  /* background-image: linear-gradient(#13547a, #80d0c7); */
`;

export const servicesBody = isMobile => css`
  display: flex;
  width: 100%;
  max-width: 1024px;
  align-items: center;
  text-align: right;
  box-sizing: border-box;
  flex-direction: column;
  ${isMobile
    ? `
    padding: 15px 0;
    line-height: 22px;
    font-size: 16px;
    color: #797979;
    > div:not(:last-child) {
      padding-bottom: 20px;
    }
    border-right: 5px solid #5e31a1;
    padding-right: 20px;
  `
    : `
    padding: 30px 0;
    line-height: 25px;
    font-size: 20px;
    color: #797979;
    > div:not(:last-child) {
      padding-bottom: 20px;
    }
    > div {
      text-align: center;
    }
    border-right: 10px solid #5e31a1;
    padding-right: 20px;
  `}
  div.hidden {
    opacity: 0;
    transform: scale(0.5);
  }
  div.animate-in-element {
    opacity: 1;
    animation: ${fadeIn} 1s;
  }
`;
