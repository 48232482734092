import { css, keyframes } from "emotion";

const fadeInZoom = keyframes`
    from {opacity: 0; transform: scale(0.5);}
    to {opacity: 1; transform: scale(1);}
`;

export const contactMe = isMobile => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: auto;
  ${isMobile
    ? `
  padding: 30px 20px;
`
    : `
  padding: 40px;
`}
  box-sizing: border-box;
  background-image: linear-gradient(#e8daff0a, #6034a31a);
  min-height: fit-content;
`;

export const contactMeBody = isMobile => css``;

export const helpLinkbody = isMobile => css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 700px;
  ${isMobile
    ? `
    margin-top: 10px;
    justify-content: space-evenly;
  `
    : `
    margin-top: 40px;
    justify-content: space-around;
  `}
`;

export const mailToLink = css`
  text-decoration: none;
`;

export const helpLinkTab = isMobile => css`
  ${isMobile
    ? `
        height: 90px;
        padding: 10px;
        width: 130px;
        margin-bottom: 20px;
        font-size: 14px;
    `
    : `
        height: 150px;
        padding: 20px;
        width: 190px;
        margin-bottom: 40px;
        font-size: 20px;
    `}
  box-sizing: border-box;
  background-image: linear-gradient(
    155deg,
    #333150 10%,
    #6435ab 50%,
    #230e3c 90%
  );
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  box-shadow: inset -2px -2px 10px #ffffff;
  transition: 0.2s ease-in;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
    transition: 0.5s ease-out;
  }
  &.hidden {
    opacity: 0;
    transform: scale(0.5);
  }
  &.animate-in-element {
    opacity: 1;
    animation: ${fadeInZoom} 0.5s;
  }
`;
