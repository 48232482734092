import { css } from "emotion";

export const footer = isMobile => css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-image: linear-gradient(
    155deg,
    #333150 10%,
    #6435ab 50%,
    #230e3c 90%
  );
  color: white;
  font-family: "Montserrat", sans-serif;
  ${isMobile
    ? `
    font-size: 12px;
  `
    : `
    font-size: 14px;  
  `}
`;
