import { css } from "emotion";

export const particles = (color, id) => css`
  canvas {
    display: block;
  }
  #${id} {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: ${color};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -1;
    ${id === "main-header" &&
      `
        height: 100vh;
    `}
  }
`;
