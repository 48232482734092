import { css, keyframes } from "emotion";

const fadeIn = keyframes`
    from {opacity: 0; transform: scale(.7,.7)}
    to {opacity: 1;}
`;

export const header = isMobile => css`
  background: -webkit-linear-gradient(#7301ff, #240258);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  text-transform: uppercase;
  ${isMobile
    ? `
      font-size: 22px;
      margin-bottom: 25px;
  `
    : `
      font-size: 44px;
      margin-bottom: 20px;
  `}
  ::after {
    position: absolute;
    content: "";
    display: block;
    background-image: linear-gradient(to right, #7301ff, #240258);
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 6px;
    ${isMobile
      ? `
        width: 50%;
        height: 2px;
    `
      : `
        width: 50%;
        height: 3px;
    `}
  }
  &.hidden {
    opacity: 0;
  }
  &.animate-in-element {
    animation: ${fadeIn} 1.4s;
  }
`;
