import React from "react";
import * as s from "./style";
import Header from "../../components/Header";

class Services extends React.Component {
  render() {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={s.services(isMobile)} id="SERVICES">
        <Header text="SERVICES" isMobile={isMobile} />
        <div className={s.servicesBody(isMobile)}>
          <div className="hidden">
            I am always ready for open source development.
          </div>
          <div className="hidden">
            I like to give seminars on Web Technology in Colleges and
            Institutions.
          </div>
          <div className="hidden">
            I have given Pre Placement Talks and Sessions in certain
            institutions that have resulted in a very optimal performance of
            students. Always open for such Sessions.
          </div>
          <div className="hidden">
            Very experienced in developing Responsive Dynamic Portfolio with
            Animations that can be also used as a source of Web App.
          </div>
          <div className="hidden">
            Ready for any technological help related to Web Technology.
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
