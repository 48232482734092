import { css } from "emotion";

export const headerContainer = (isMobile) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  ${isMobile
    ? `
        padding: 0 20px;
        height: 75px;
    `
    : `
        padding: 0 40px;
        height: 100px;
    `}
`;

export const logoContainer = () => css`
  display: flex;
  align-items: center;
`;

export const logo = (isMobile) => css`
  font-weight: 600;
  color: white;
  background: -webkit-linear-gradient(#ccc, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${isMobile
    ? `
        font-size: 36px;
    `
    : `
        font-size: 70px;
    `}
`;

export const logoText = (isMobile) => css`
  color: white;
  font-weight: 600;
  background: -webkit-linear-gradient(#ccc, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${isMobile
    ? `
        font-size: 28px;
        margin-left: 15px;
    `
    : `
        font-size: 44px;
        margin-left: 20px;
    `}
`;

export const burgerIconContyainer = () => css`
  display: flex;
`;

export const hamburger = () => css`
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
`;

export const hamburgerBox = (showMobileMenu) => css`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  ${showMobileMenu
    ? `
        vertical-align: middle;
    `
    : ``}
`;

export const hamburgerInner = (showMobileMenu) => css`
  top: auto;
  bottom: 0;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.13s;
  position: absolute;
  width: 24px;
  height: 2px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff;
  display: block;
  margin-top: -2px;
  ::after {
    position: absolute;
    width: 24px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
    display: block;
    content: "";
    top: -14px;
    transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      opacity 0.1s linear;
    ${showMobileMenu
      ? `
            top: 0;
            transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
            opacity: 0;
        `
      : ``}
  }
  ::before {
    position: absolute;
    width: 24px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
    display: block;
    content: "";
    top: -7px;
    transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${showMobileMenu
      ? `
            top: 0;
            transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s;
            transform: rotate(-90deg);
        `
      : ``}
  }
  ${showMobileMenu
    ? `
        transition-delay: .22s;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transform: translate3d(0,-10px,0) rotate(-45deg);
        background-color: #fff;
    `
    : ``}
`;
