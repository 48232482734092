import React from "react";
import tapas_kumar from "../../images/tapas_kumar.png";
import * as s from "./style";
import Header from "../../components/Header";

class AboutMe extends React.Component {
  render() {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={s.aboutMe(isMobile)} id="ABOUT">
        <Header text="About Me" isMobile={isMobile} />
        <div className={s.aboutMeBody(isMobile)}>
          <div className={s.myDetails(isMobile)}>
            <div className={`${s.myDetailsBody(isMobile)} hidden`}>
              <div>
                Hey, I am from LOHNAGRI of INDIA, i.e. JAMSHEDPUR, JHARKHAND. I
                am a FULL STACK DEVELOPER. Currently I am working as a SOFTWARE
                DEVELOPER at Lyearn Pvt Ltd. I started my career on Jan 2017,
                with Justdial Pvt Ltd, as a SOFTWARE DEVELOPER. I worked at
                Justdial Pvt Ltd for 2 years. I LOVE CODING and have very good
                experience in developing Dynamic Responsive Optimised Websites.
              </div>
              <div>
                Apart from this, I love Tutoring Students, and also give
                seminars on Frontend Development in Colleges.
              </div>
            </div>
          </div>
          <div className={`${s.myPic()} hidden`} title="Tapas Kumar">
            <img className={s.myImage()} alt="Tapas Kumar" src={tapas_kumar} />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutMe;
