import html5 from "../images/html5.png";
import css3 from "../images/css3.png";
import js from "../images/js.jpg";
import react from "../images/react.png";
import mobx from "../images/mobx.png";
import emotionjs from "../images/emotionjs.png";
import redux from "../images/redux.png";
import nodejs from "../images/nodejs.png";
import angularjs1 from "../images/angularjs1.png";
import jquery from "../images/jquery.jpeg";
import graphql from "../images/graphql.png";
import mongodb from "../images/mongodb.jpg";
import bootstrap from "../images/bootstrap.jpg";
import es6 from "../images/es6.png";
import webpack from "../images/webpack.png";
import ts from "../images/ts.png";

const getSkillsList = () => [
  {
    text: "HTML5",
    imageSrc: html5,
    imageAlt: "HTML5",
    percent: 80,
    color: "#e83e2f",
  },
  {
    text: "CSS3",
    imageSrc: css3,
    imageAlt: "CSS3",
    percent: 90,
    color: "#009ce5",
  },
  {
    text: "JS",
    imageSrc: js,
    imageAlt: "JS",
    percent: 90,
    color: "#fed93e",
  },
  {
    text: "React 16",
    imageSrc: react,
    imageAlt: "React",
    percent: 90,
    color: "#1de2ff",
  },
  {
    text: "Mobx",
    imageSrc: mobx,
    imageAlt: "Mobx",
    percent: 80,
    color: "#e05e11",
  },
  {
    text: "Emotion JS",
    imageSrc: emotionjs,
    imageAlt: "Emotion JS",
    percent: 100,
    color: "#d368c2",
  },
  {
    text: "Redux",
    imageSrc: redux,
    imageAlt: "Redux",
    percent: 60,
    color: "#794cbf",
  },
  {
    text: "ES6",
    imageSrc: es6,
    imageAlt: "ES6",
    percent: 100,
    color: "#e9c920",
  },
  {
    text: "Node JS",
    imageSrc: nodejs,
    imageAlt: "Node JS",
    percent: 70,
    color: "#95c46e",
  },
  {
    text: "Typescript",
    imageSrc: ts,
    imageAlt: "Typescript",
    percent: 70,
    color: "#007bce",
  },
  {
    text: "Angular JS 1",
    imageSrc: angularjs1,
    imageAlt: "Angular JS",
    percent: 60,
    color: "#b82933",
  },
  {
    text: "JQuery",
    imageSrc: jquery,
    imageAlt: "JQuery",
    percent: 80,
    color: "#1068ad",
  },
  {
    text: "GraphQL",
    imageSrc: graphql,
    imageAlt: "GraphQL",
    percent: 40,
    color: "#e531ac",
  },
  {
    text: "Mongo DB",
    imageSrc: mongodb,
    imageAlt: "Mongo DB",
    percent: 50,
    color: "#4dac53",
  },
  {
    text: "Bootstrap",
    imageSrc: bootstrap,
    imageAlt: "Bootstrap",
    percent: 70,
    color: "#563e7c",
  },
  {
    text: "Webpack",
    imageSrc: webpack,
    imageAlt: "Webpack",
    percent: 50,
    color: "#8ed5fb",
  },
];
export default getSkillsList;
