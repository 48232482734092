import { observable } from "mobx";

class RootStore {
  windowHeight = window.innerHeight;

  @observable currentTab = "HOME";
  @observable showStickyHeader = false;
  @observable isMobile = window.innerWidth <= 768;
  @observable threshold = window.innerWidth <= 768 ? 75 : 100;
  @observable showMobileMenu = false;
}

export default RootStore;
