import React from "react";
import Portfolio from "./model-views/Portfolio";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Portfolio />
    </div>
  );
}

export default App;
