import React from "react";
import * as s from "./style";

class Footer extends React.Component {
  render() {
    const { model } = this.props;
    const { store } = model;
    const { isMobile } = store;
    return (
      <div className={s.footer(isMobile)}>
        Copyright © 2019 @tapaskumar.in All Rights Reserved.
      </div>
    );
  }
}

export default Footer;
